.my-custom-datepicker-style input{
    margin-left:40px
}
#date{
    padding-left:40px
}
 
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.trn-point > span{
    background-color: #1E429F;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.sick-point > span{
    background-color: #d69303;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.otr-point > span{
    background-color: #383835;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.rqf-point > span{
    background-color: #72051c;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.anl-point > span{
    background-color: #029c3d;
    padding: 8px;
}

#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.off-point > span{
    background-color: #9c0702;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.clv-point > span{
    background-color: #459702; 
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.ulv-point > span{
    background-color: #e63921;
    padding: 8px;
}
#root > div > main > div.flex.flex-col > div > div > div > ol > li  div.od-point > span{
    background-color: #612D5F;
    padding: 8px;
}
#root > div > div > main > div.flex.flex-col > div > div > div > ol > li  > div.od-point > span {
    background-color: #612D5F;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.sby-point > span{
    background-color: #118D72;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  div.off-point > span{
    background-color: #be1c07;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li > div  > span{
    height: 2rem;
    width: 2rem;
    background-color: #200bdf;
    color:#fff;
    box-shadow: none;
    padding: 8px;
}
#root > div> div > main > div.flex.flex-col > div > div > div > ol > li  > div  > span > svg{
    fill: #fff;
    width: 1.5rem;
    height: 1.5rem;
}

 .border-orange-600.flex.flex-col.justify-items-start > div{
    padding : 10px 15px;
}
.border-teal-600.flex.flex-col.justify-items-start > div{
    padding : 10px 15px;
}
time{
    font-size: 1.5rem !important;
    color: #1b0668 !important;
    font-weight: 600 !important;
    padding-left: 20px;
    
}
 
 .officeduty-badge{
    background-color: #612D5F;
    padding: 15px;
    border-radius: 10px;
 }
 .trainingblock-badge{
    background-color: #1D2173;
    padding: 15px;
    border-radius: 10px;
 }
 .stanby-block-badge{
    background-color: #118d72;
    padding: 15px;
    border-radius: 10px;
 }
 .stanby-block-header{
    
    background-color: #118d72;
 }
 .stick-leave-card{
    background-color: #d69303;
     
 }
 .annual-leave-card{
    background-color: #029c3d;
 }  
 .unpaid-leave-card{
    background-color: #e63921;
 }
 .casual-leave-card{
    background-color: #459702;
 }

 .bg-amber-400{
    background-color: #ffaf04;
 }

 .layover-block-badge{
    background-color: #029c3d;
    padding: 15px;
    border-radius: 10px;
 }

 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol{
    /* border-left: #029c3d 3px dashed !important; */
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-flight::after {
    content: '';
    position: absolute;
    left: -12px; /* Centers the arrow on the border */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 17px solid #05029c; /* Color of the arrow */
}
.crewinfo-drw-head{
    background-color: #1b0668;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
} 
.crewinfo-drw-head > button{
background-color: #FFF;
}
.crewinfo-drw-head h5{
    font-size: 1.2REM;
    color: #fff;
     
} 
ul > li > svg{
    fill: #029c3d;
}
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-flight{
    border-left: #05029c 4px solid !important;
    position: relative;
 }
 
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-off{
    border-left: #b9001f 4px dotted !important;
 }

 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-annl{
    border-left: #16b900 4px dotted !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-offdt{
    border-left: #612D5F 4px solid !important;
 }
 
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-trn{
    border-left: #1004bb 4px solid !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-sby{
    border-left: #08915c 4px solid !important;
 }
 
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-otr{
    border-left: #312f2f 4px dotted !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-eml{
    border-left: #bb0404 4px dotted !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-sick{
    border-left: #d69303 4px dotted !important;
 }
 
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-ulv{
    border-left: #e63921 4px dotted !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-else{
    border-left: #3c3c3d 4px dotted !important;
 }
 
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li.li-clv{
    border-left: #459702 4px solid !important;
 }
 #root > div > div > main > div.z-40.flex.min-h-full.flex-col > div > div > div > ol > li{
  
    padding-left: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding-bottom: 40px;
 }