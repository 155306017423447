.bg-topography{
    background-color: #3d47fe; 
    background-image: url("../../images/gray_line_drawings_of_organic_shapes_background.jpg");
  background-position: center;
  background-size: cover;
}

@keyframes slideInFromRight {
    0% {
        transform:  translateX(100%);
        opacity: 0;
      }
      100% {
        transform:  translateX(0);
        opacity: 1;
      }
  }

 .toast-postion{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    animation: slideInFromTopRight 0.5s ease forwards;  
}